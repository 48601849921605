import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import {
  ContactCard,
  EContactCardLayout,
} from "@organic-return/foundation-react/src/components/Contact/ContactCard"
import { Search } from "@organic-return/foundation-react/src/components/Search/Search"
import { useSiteContext } from "../common/SiteContext"

const PeopleSearch = ({ module }: any) => {
  const siteContext = useSiteContext()
  const data = useStaticQuery(graphql`
    query PeopleSearchQuery {
      allPerson(filter: {isHidden: {ne: true}},sort: {fields: last, order: ASC}) {
        nodes {
          bio
          category
          company
          email
          first
          fullName
          last
          mlsID
          officeName
          originID
          path
          phone
          phoneMobile
          phoneOffice
          workTitle          
          social {
            channel
            url
          }
          photos {
            url
          }
          type
          office {
            address {
              city
            }
          }
        }
      }
    }
  `)
  // Small card size option (default)
  let cardClass = "w-[400px]"
  let cardImageWidth = 400
  // Large card size option
  if (module.customFields?.cardSize == "large") {
    cardClass = "w-[580px]"
    cardImageWidth = 580
  }

  // Configurable results per page defaults to 20
  let pageSize = module.customFields?.pageSize || 0
  return (
    <Search
      index={data.allPerson.nodes}
      pageSize={pageSize}
      showCount={true}
      renderItem={(item, index) => {
        return (
          <ContactCard
            contact={item}
            layout={EContactCardLayout.horizontal}
            maxWidthImage={200}
            className="w-full sm:w-1/2 xl:w-1/3 p-4"
            showVCard={false}
            buttonText={`About ${item.first || item.fullName}`}
            key={index}
            linkFunc={props => {
              return (
                <Link
                  to={props.href ?? ""}
                  className={`text-inherit ${props.className}`}
                  key={props.key}
                  title={props.title}
                >
                  {props.children}
                </Link>
              )
            }}
            {...siteContext.globalPeopleSearchCardProps}
          />
        )
      }}
      formBackgroundImage={module.customFields.formBackgroundImage?.url}
      title={module.customFields.formTitle}
      preRenderItem={(item, index) => {
        return (
          <a key={index} href={item.path}></a>
        )
      }}
      {...siteContext.peopleSearchProps}
    />
  )
}

export default PeopleSearch
